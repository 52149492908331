import React, { useState, useCallback, useEffect } from 'react'
import Keycloak from 'keycloak-js'
import jwtDecode from 'jwt-decode'
import * as P from 'prop-types'
import actions from '../../requests/event'
import TagManager from 'react-gtm-module'
import {GTM_KEY} from "../../static/appConfig";
import teamActions from "../../requests/team";

export const AuthContext = React.createContext({})

let updateTokensTimerId = undefined
const secondsToMillis = x => x * 1000
const keycloak = Keycloak({
  url: window.location.hostname.startsWith('dev.') || window.location.hostname === 'localhost' ? 'https://kca.inkyphishfence.com/auth' : 'https://auth.dashboard.inky.com/auth',
  realm: 'inky',
  clientId: 'inky-dashboard',
})

const INITIAL_AUTH_STATE = {
  authenticating: false,
  errors: undefined,
  tokens: undefined,
  user: undefined,
  roles: undefined,
  permissions: undefined,
}

function registerEvent(event_actions, results, errors) {
  let email = ''
  if (keycloak?.idTokenParsed) {
    email = keycloak.idTokenParsed?.preferred_username
  } else {
    if (keycloak.token) {
      keycloak.loadUserProfile(function() {
        email = keycloak?.profile?.username
      })
    }
  }
  let eventData = {}
  if (event_actions)
    eventData['actions'] = event_actions
  if (results)
    eventData['results'] = results
  if (errors)
    eventData['errors'] = errors
  actions.registerAuthEventAction(email, null, eventData)
}

function getAuthState(tokens) {
  const { accessToken } = tokens
  const tokenData = accessToken ? jwtDecode(accessToken) : {}

  return {
    authenticating: false,
    errors: undefined,
    user: {
      id: tokenData.sub,
      name: tokenData.name,
      email: tokenData.email,
      given_name: tokenData.given_name,
      family_name: tokenData.family_name,
      preferred_username: tokenData.preferred_username,
    },
    tokens: tokens,
    permissions: accessToken ? ['view:dashboard'] : undefined, // TODO: get permissions from token
    teams: ['client1', 'client2'],
    realm: tokenData.iss.match(/\/realms\/.*/)[0].replace('/realms/', ''),
  }
}

function AuthProvider(props) {
  const [authState, setAuthState] = useState(INITIAL_AUTH_STATE)
  const [lastLoginEmail, setLastLoginEmail] = useState()

  const resetAuthState = useCallback(() => setAuthState(INITIAL_AUTH_STATE), [])

  const authPending = useCallback(
    () => setAuthState(prevAuthState => ({ ...prevAuthState, authenticating: true })),
    []
  )

  const authFailure = useCallback(
    payload =>
      setAuthState(prevAuthState => ({
        ...prevAuthState,
        authenticating: false,
        errors: payload,
        user: undefined,
      })),
    []
  )

  const authSuccess = useCallback(
    tokens => {
      let newAuthState = getAuthState(tokens)
      setLastLoginEmail(newAuthState.user?.preferred_username)
      setAuthState(prevAuthState => ({
        ...prevAuthState,
        ...newAuthState,
      }))
    },
    []
  )

  const usernameBasedLogin = useCallback(async credentials => {
    authPending()
    try {
      keycloak.login() // Use Keycloak login page
      registerEvent(['manual_login'])
    } catch (error) {
      registerEvent(['manual_login'], ['error'], [error.message])
      authFailure(error)
    }
  })

  const msLogin = useCallback(() => {
    authPending()
    try {
      keycloak.login({ idpHint: 'oidc' })
      registerEvent(['ms_oidc_login'])
    } catch (error) {
      authFailure(error)
      registerEvent(['ms_oidc_login'], ['error'], [error.message])
    }
  })

  const gLogin = useCallback(() => {
    authPending()
    try {
      keycloak.login({ idpHint: 'google' })
      registerEvent(['google_login'])
    } catch (error) {
      authFailure(error)
      registerEvent(['google_login'], ['error'], [error.message])
    }
  })

  const logout = useCallback(
    async () => {
      try {
        if (keycloak.token) {
          keycloak.logout()
          registerEvent(['logout'], ['success'])
        }
      } catch (error) {
        console.log(error)
        registerEvent(['logout'], ['error'], [error.message])
      } finally {
      }
    },
    [authState.tokens]
  )

  const clearUpdateTokensTimeout = useCallback(() => {
    clearTimeout(updateTokensTimerId)
    updateTokensTimerId = undefined
  }, [])

  const updateTokens = useCallback(
    async () => {
      clearUpdateTokensTimeout()
      try {
        keycloak.updateToken(45)
          .success(refreshed => {
            let tokens = {accessToken: keycloak.token, refreshToken: keycloak.refreshToken, idToken: keycloak.idToken}
            registerEvent(['refresh_token'], ['success','refreshed='+refreshed])
            authSuccess(tokens)
          })
          .error(() => {
            registerEvent(['refresh_token'], ['error'])
            authFailure()
          })
      } catch (error) {
        registerEvent(['refresh_token'], ['error'], [error.message])
        authFailure()
      }
    },
    [authState.tokens]
  )

  useEffect(() => {
    keycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      redirectUri: window.location.origin + window.location.pathname,
    }).success(authenticated => {
      if (authenticated) {
        registerEvent(['keycloak_init'], ['authorized'])
        authSuccess({
          accessToken: keycloak.token,
          idToken: keycloak.idToken,
          refreshToken: keycloak.refreshToken,
        })
        teamActions.getTeamsAction({realm: keycloak?.realm, token: keycloak?.token})
          .then(teams => {
            TagManager.initialize({
              gtmId: GTM_KEY,
              dataLayer: {
                event: 'viz_dash_user_authenticated',
                email: teams?.data?.authEmail,
                teamid: teams?.data?.memberOf,
                license_key: teams?.data?.licenseKey,
                team_type: teams?.data?.teamType
              }
            })
          })
      } else {
        registerEvent(['keycloak_init'], ['unauthorized'], ['check-sso not authenticated'])
        authFailure()
      }
    }).error(err => {
      console.log("Keycloak Init Error", err)
      let errors = []
      if (err?.error)
        errors.push(err.error)
      if (err?.error_description)
        errors.push(err.error_description)
      registerEvent(['keycloak_init'], ['error'], errors)
    })

    keycloak.onAuthLogout = () => {
      resetAuthState()
    }

    // Technically, we should never get here.  We check in the timer if the token will expire within 45 seconds, and we
    // call that method every 30 seconds, so this is just for backup
    keycloak.onTokenExpired = () => {
      updateTokens()
    }
  }, [])

  useEffect(
    () => {
      const { tokens } = authState
      if (tokens && !updateTokensTimerId) {
        // Refresh the tokens 25 seconds before it expires (expiration is set to 300 sec)
        const t = secondsToMillis(275)
        updateTokensTimerId = setTimeout(updateTokens, t)
      }
      return () => clearUpdateTokensTimeout()
    },
    [authState.tokens]
  )

  const auth = {
    ...authState,
    usernameBasedLogin,
    logout,
    gLogin,
    msLogin,
    lastLoginEmail,
  }

  window.refreshToken = updateTokens

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  )
}

export const authPropType = P.shape({
  authenticating: P.bool,
  errors: P.object,
  tokens: P.object,
  user: P.object,
  permissions: P.array,
  roles: P.array,
  usernameBasedLogin: P.func.isRequired,
  logout: P.func.isRequired,
})

export default AuthProvider
