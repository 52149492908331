import styled from 'styled-components'

export const colors = {
  white: '#ffffff',
  secondaryMain: '#e01883',
  black: '#000000',
  secondaryDark: '#ba0a68',
  secondaryLight: '#e5449a',
  primaryMain: '#00205c',
  primaryDark: '#7384a5',
  primaryLight: '#c4c9d4',
  primaryLighter: '#dbdfe7',
  darkGrey: '#353c43',
  primaryExtraLight: '#efeff3',
}

export const spacing = {
  xs: '0.5rem',
  s: '1rem',
  m: '1.5rem',
  l: '2rem',
  xl: '2.5rem',
  xxl: '3rem',
  xxl2: '3.5rem',
  xxl3: '4rem',
  xxl4: '4.5rem',
  xxs: '0.25rem',
  xs2: '0.75rem',
}

export const Text = styled.div`
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const HeadlineH1PrimaryMainBold = styled(Text)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH1PrimaryDarkBold = styled(Text)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.primaryDark};
`

export const HeadlineH2PrimaryMainBold = styled(Text)`
  font-size: 1.313rem;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH3PrimaryMainBlack = styled(Text)`
  font-size: 1rem;
  font-weight: 900;
  color: ${colors.primaryMain};
`

export const HeadlineH3PrimaryMainRegular = styled(Text)`
  font-size: 1rem;
  color: ${colors.primaryMain};
`

export const HeadlineH3WhiteRegular = styled(Text)`
  font-size: 1rem;
  color: ${colors.white};
`

export const HeadlineH3PrimaryLigtherRegular = styled(Text)`
  font-size: 1rem;
  color: ${colors.primaryLighter};
`

export const HeadlineH3PrimaryLightRegular = styled(Text)`
  font-size: 1rem;
  color: ${colors.primaryLight};
`

export const BodyPrimaryMainBold = styled(Text)`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const BodyPrimaryMainRegular = styled(Text)`
  font-size: 0.875rem;
  color: ${colors.primaryMain};
`

export const BodyDarkGreyRegular = styled(Text)`
  font-size: 0.875rem;
  color: ${colors.darkGrey};
`

export const BodyPrimaryLightRegular = styled(Text)`
  font-size: 0.875rem;
  color: ${colors.primaryLight};
`

export const SubtitlePrimaryMainRegular = styled(Text)`
  font-size: 0.875rem;
  color: ${colors.primaryMain};
`

export const Body2DarkGreyRegular = styled(Text)`
  font-size: 0.813rem;
  color: ${colors.darkGrey};
`

export const BodyXSPrimaryMainRegular = styled(Text)`
  font-size: 0.75rem;
  color: ${colors.primaryMain};
`

export const BodyXSPrimaryDarkRegular = styled(Text)`
  font-size: 0.75rem;
  color: ${colors.primaryDark};
`

export const BodyXSDarkGreyRegular = styled(Text)`
  font-size: 0.75rem;
  color: ${colors.darkGrey};
`
