import React from 'react'
import { Router } from '@reach/router'
import ErrorBoundary from '../ErrorBoundary'

const Dashboard = React.lazy(() => import('./Dashboard'))
const Message = React.lazy(() => import('./Message'))
const Marketplace = React.lazy(() => import('./Marketplace'))

/*
const App = () => (
  <Router>
    <Dashboard path="/" />
    <Message path="/messages/:messageIndex/:messageId" />
  </Router>
)
*/

const App = ({ initialRoute }) => (
  <ErrorBoundary
    fallback={
      <Router style={{ width: '100%', height: '100%' }}>
        <Dashboard path='/*' initialRoute={initialRoute} />
        <Message path='/messages/:messageIndex/:messageId' />
        <Marketplace path='/marketplace' />
      </Router>
    }
  >
    <Router style={{ width: '100%', height: '100%' }}>
      <Dashboard path='/*' initialRoute={initialRoute} />
      <Message path='/messages/:messageIndex/:messageId' />
      <Marketplace path='/marketplace' />
    </Router>
  </ErrorBoundary>
)

export default App
