import React, { Suspense } from 'react'
import { QueryCache, ReactQueryConfigProvider } from 'react-query'
import { Location, Router } from '@reach/router'
import { Loader } from 'semantic-ui-react'
import { AuthProvider } from './@logicea/react-auth'
import Auth from './components/Auth'
import App from './components/App'
import I18nProvider from './lib/i18n/I18nWrapper'
import { Theme } from 'providers/Theme'
import TagManager from "react-gtm-module";
import {GTM_KEY} from "./static/appConfig";

const queryConfig = new QueryCache({
  defaultConfig: {
    queries: {
      suspense: true,
    },
  },
})

TagManager.initialize({
  gtmId: GTM_KEY,
  dataLayer: {
    event: 'page_view',
  }
})

const Root = () => {
  return (
    <Location>
      {({ location }) => {
        let initialRoute = localStorage.getItem('initialRoute')
        if (
          location &&
          location.pathname &&
          location.pathname.startsWith('/reports')
        ) {
          initialRoute = location.pathname
          localStorage.setItem('initialRoute', initialRoute)
        }

        return (
          <div style={{ width: '100%', height: '100%', fontFamily: 'Lato' }}>
            <Suspense fallback={<Loader active />}>
              <ReactQueryConfigProvider config={queryConfig}>
                <Theme>
                  <I18nProvider>
                    <AuthProvider>
                      <Router style={{ width: '100%', height: '100%' }}>
                        <Auth path='authentication/*' />
                        <App path='/*' initialRoute={initialRoute} />
                      </Router>
                    </AuthProvider>
                  </I18nProvider>
                </Theme>
              </ReactQueryConfigProvider>
            </Suspense>
          </div>
        )
      }}
    </Location>
  )
}

export default Root
