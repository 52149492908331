export const WHITE = '#FFFFFF'
export const BACKGROUND_LIGHT = '#FFFFFF'
export const BACKGROUND_MEDIUM = '#F5F6F9'
export const BACKGROUND_DARK = '#E9EBF0'
export const PRIMARY_DARK = '#00205C'
export const LIGHT_TEXT = '#FFFFFF'
export const MEDIUM_TEXT = '#979A9E'
export const DARK_TEXT = '#4A4A4A'
export const DARK_GREY = '#848991'
export const MEDIUM_GREY = '#9B9B9B'
export const LIGHT_GREY = '#ECECEC'
export const STATISTICS_BACKGROUND = '#EDEDED'
export const DASHBOARD_FILTER_BAR = '#E8ECF3'
export const UNSELECTED_BUTTON = '#ECECEE'
export const HEADER_BACKGROUND = '#F0F2F7'
export const IMPORTANT_RED = '#d00909'
export const CHART_DARK = '#270089'
export const CHART_MEDIUM = '#94368D'
export const CHART_LIGHT = '#E01883'
export const BORDER_LIGHT = '#E4E7EF'
export const BORDER_MEDIUM = '#F4F4F4'
export const CHART_SAFE = '#6C757D'
export const CHART_CAUTION = '#F0AD4E'
export const CHART_DANGER = '#D9534F'
export const SELECTED_FILTER = '#CFA1DA'
export const SEPARATOR = 'rgba(151, 151, 151, 0.46)'
export const PLACEHOLDER = '#F0F0F0'
export const INPUT_TEXT = '#878687'
export const INPUT_TEXT_PLACEHOLDER = '#B7B6B7'
export const LINK_TEXT = '#C0C0C0'
export const TABLE_BORDER = '#F0F2F7'
export const VISUALIZATION_TICK_DARK = '#616161'
export const VISUALIZATION_BACKGROUND = '#FFFFFF'
export const MESSAGE_SET_BAR = '#F8F8F8'
export const MODAL_SIDEBAR = '#F0F2F7'
export const MESSAGE_HEADER_LABEL = '#A7A7A7'
export const MESSAGE_ANALYSIS_LEVEL = '#1C1C1C'
export const LAYOUT_SEPARRATOR = '#E0E0E0'
export const TOOLTIP_LABEL = '#31394D'
export const CLOSE_BUTTON_HOVER = '#E2E2E2'
export const SUGGESTION_BACKGROUND = '#F0F2F7'
export const MODAL_OVERLAY = '#2F2F2F80'
export const MORE_ICON = '#CBCBCB'
export const LEGEND_TEXT = '#353C43'
export const NEGATIVE_VALUE = '#5C2600'
export const BUTTON_BORDER = '#DADFEC'
export const ACTION_BUTTON_BG = '#1883e0'
export const HIGHLIGHTED_GREEN = '#006f00'
export const ROW_ITEM_HOVER = '#e8e8e8'

export const REASON_ID_COLORS = [
  '#844190',
  '#419084',
  '#908441',
  '#e01883',
  '#1883e0',
  '#83e018',
  '#1f499f',
  '#1f9f75',
  '#9f1f49',
  '#4d9041',
]
