import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from 'static/theme'

const custom = {
  palette: {
    primary: {
      main: colors.primaryMain,
      dark: colors.primaryDark,
      light: colors.primaryLight,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondaryMain,
      dark: colors.secondaryDark,
      light: colors.secondaryLight,
      contrastText: colors.white,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        textTransform: 'none' as const,
      },
    },
  },
}

export const customTheme = createMuiTheme(custom)
